.profile-header {
  img {
    border-radius: 50px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  h3,
  p {
    margin: 0;
    margin-left: 2rem;
    color: var(--color-user-dark);
  }
}
