.log-timer-container {
  background-color: rgb(0, 173, 0);
  border-radius: 0.25rem;
  text-align: center;
  padding-block: 3px;
  width: 6.25rem;
  user-select: none;
  @media only screen and (max-width: 1363.98px) {
    padding-block: 2.5px;
  }
}

.log-timer {
  color: #fff;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  .fa-pause {
    margin-right: 0.5rem;
  }
  .timer-count {
    width: 3.2rem;
  }
}
