.client-header-actions {
  .needs-validation {
    float: right;
  }
  .user-select-none.form-control {
    margin-bottom: 0 !important;
  }
  .date-input-container {
    margin-right: 1rem !important;
  }
  .table-container-add-view {
    margin-top: 3.25rem !important;

    .select-user-wrapper {
      margin-left: auto;
      width: 90%;
      .form-group {
        margin-bottom: 0 !important;
      }
      .main-text-color {
        padding-left: 0 !important;
      }
    }
  }
}
.fira-sans-text {
  font-family: 'Fira Sans', sans-serif;
}
.client-logs-card-body {
  padding: 0;
}
.client-logs-card-footer {
  border-top: none;
  padding-block: 1rem;
}
.client-data-spinner {
  display: grid;
  place-items: center;
  color: var(--color--secondary--user);
  padding: 10rem;
  font-size: 5rem;
}

.client-logs-table {
  th:first-child {
    width: 0;
  }

  th:nth-last-child(7) {
    width: 20%;
  }
  th:nth-last-child(6) {
    width: 16%;
  }
  th:nth-last-child(5) {
    width: 8%;
  }
  th:nth-last-child(4) {
    width: 25%;
  }
  th:nth-last-child(3) {
    width: 15%;
  }
  th:nth-last-child(2) {
    width: 15%;
  }

  th:last-child {
    width: 5%;
  }
}
