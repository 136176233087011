.client-details {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media (max-width: 800px) {
  .client-details {
    display: unset;
  }
}
@media (min-width: 1700px) {
  .client-details {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
}
.client-attribute {
  margin-block: 0.5rem;
}
.client-key {
  font-size: 0.7rem;
  color: #a7a8ab;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.client-value {
  font-size: 0.92rem;
}

.details-card-header,
.custom-breadcrumb-admin {
  font-weight: 500;
}
.details-card-header {
  font-size: 1.0625rem;
  margin: 0.2rem 0;
}

.details-card-body {
  padding-top: 0.75rem;
}

.logs-spinner.archived-modal {
  margin-block: 2.125rem 3rem;
}

.link-bold {
  font-weight: 500;
  font-family: 'Fira Sans';
  cursor: pointer;
  user-select: none;
  &.color-blue {
    color: #64a2ff;
  }
  &.color-red {
    color: #ff7656;
  }
}
