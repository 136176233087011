:root {
  --color-primary-user: #aaa;
  --color--primary--user: #aaa;
  --color-user-light: #fbdb96;
  --color-secondary-user: #fdc13f;
  --color--secondary--user: #fdc13f;
  --color-user-dark: #40413d;
  --color-warning: #f5365c;
  --color-description: #ee9348;
  --color-secondary-user-light: rgb(223, 223, 223);
  --clr-light-gray: #53514d;
  --clr-lighter-gray: #8d8c88;
  --clr-dark-gray: #363633;
  /* Button colors */
  --button-bg-color: #ffffff;
  --button-text-color: #8d8c88;

  --button-bg-color-hover: #eeeeee;
  --button-text-color-hover: #463e2c;

  --button-bg-color-active: #363633;
  --button-text-color-active: #ffffff;

  // font family
  --ff-primary: 'Fira Sans', sans-serif;
}
i {
  cursor: pointer;
}
.text-green {
  color: #beea0f !important;
}

/*VALIDATIONS STYLING*/

.invalid-feedback {
  margin-left: 0.5rem;
  font-size: 0.75rem !important;
}
.b-r-top-right {
  border-top-right-radius: 0.4rem !important;
}
.custom-invalid-feedback .input-group-alternative,
.custom-invalid-feedback .custom-select-toggle {
  box-shadow: 0px 0px 0px 1px var(--color-warning);
  border-radius: 0.4rem;
}
.custom-invalid-feedback-date .form-control {
  box-shadow: 0px 0px 0px 1px var(--color-warning) !important;
}

.form-control {
  padding-left: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.custom-invalid-feedback-text {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
  color: var(--color-warning);
}
.custom-info-feedback-text {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
  color: var(--color-description);
}
.table-actions {
  .custom-invalid-feedback-text {
    display: none;
  }
}

.custom-invalid-modal-feedback {
  margin-left: 0.25rem !important;
  color: var(--color-warning);

  font-weight: 300;
}

.hidden-class {
  visibility: hidden;
}

.w-12 {
  width: 12% !important;
}

.w-5 {
  width: 5%;
}

/* LINK CLASSES */

.custom-link-class {
  color: var(--color--primary--user);
  font-weight: 600;
  font-size: 0.83rem;
}
.custom-link-class:hover {
  color: var(--color--secondary--user);
  font-weight: 600;
}
.custom-link-class-active {
  color: #ff8f2e;
}
.custom-link-class > .fa-eye {
  font-size: 1rem;
}
.bg-gradient-custom {
  background: linear-gradient(87deg, #49a09d 0%, #085078 100%);
}
.bg-gradient-custom-light {
  background: linear-gradient(87deg, #f6f9fc 50%, #fafafa 100%) !important;
}
.bg-custom {
  background-color: var(--color-background);
}
.fill-danger {
  fill: var(--color-background);
}
.btn-custom {
  background-color: var(--color-background-light) !important;
  border-color: var(--color-background-light) !important;
}
.btn-custom:hover {
  background-color: var(--color-background-light) !important;
  border-color: var(--color-background-light) !important;
}
.custom-label {
  padding: 0.5rem;
  margin: 0;
  font-family: 'sanFranciso', sans-serif !important;
}
.filter-icon-wrapper {
  font-size: 1.5rem;
  color: var(--color-warning);
}
table td {
  white-space: initial !important;
}
.custom-flex-class {
  display: flex;
}
.add-log-button {
  padding: 0.75rem 0.65rem;
  font-size: 1rem;
}
.nav-link-text {
  margin-left: 0.5rem;
}
.nav-link-text,
.user-select-none {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.no-data-div,
.no-data-card,
.no-activity-data {
  justify-content: center;
  width: 100%;
  text-align: center;
  font-style: italic;
  color: #787878;
  margin: 0.9375rem 0 1.75rem;
  font-size: 0.875rem;
}
.no-data-div.button {
  margin-bottom: 1.5rem;
}

.no-activity-data {
  margin-top: 1.75rem;
}
.no-data-card {
  margin-bottom: 0.75rem;
}

.text-disabled {
  color: #808080 !important;
}
.spinner-modal .modal-content {
  background: transparent !important;
  box-shadow: none !important;
  text-align: center;
  margin: auto;
}

.disabled-text {
  color: #aaa;
}
.disabled .page-link {
  background-color: #ccc !important;
}
.mr-6-5 {
  margin-right: 8.3rem;
}
.icons-wrapper-button {
  border: 0;
  background-color: inherit;
  color: #f5365c;
}
.icons-wrapper-button:disabled {
  color: #ccc;
  cursor: alias;
}
.icons-wrapper-button:disabled i {
  cursor: alias;
}
.pl-5-5 {
  padding-left: 2.25rem !important;
}
.custom-card-profile-image img {
  max-width: 100px;
  transition: all 0.15s ease;
}
.custom-card-profile-image img:hover {
  transform: translate(0%, -3%);
}

.modal-body {
  padding-bottom: 0.5rem;
}

.client-edit-button-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-delete-btn {
  color: #f5365c;
}

.admin-theme-button,
.admin-theme-button:focus {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border-color: var(--button-text-color);
  border-radius: 4px !important;
  box-shadow: 0px 0px 0px transparent;
}
.admin-theme-button:hover {
  background-color: var(--button-bg-color-hover) !important;
  border-color: var(--button-bg-color-hover) !important;
  color: var(--button-text-color-hover) !important;
  box-shadow: 0px 0px 0px transparent;
}
.admin-theme-button:active {
  background-color: var(--button-bg-color-active) !important;
  border-color: var(--button-bg-color-active) !important;
  color: var(--button-text-color-active) !important;
  box-shadow: 0px 0px 0px transparent;
  transform: translateY(-1px);
}

.user-theme-button {
  background-color: transparent;
  border-color: var(--color-secondary-user);
  color: var(--color-secondary-user);
}
.user-theme-button:hover {
  background-color: var(--color-secondary-user);
  border-color: var(--color-secondary-user);
}
.user-theme-button:active {
  background-color: var(--color-secondary-user) !important;
  border-color: var(--color-secondary-user) !important;
}
.custom-btn-size {
  font-size: 0.8rem;
  padding: 0.35rem 0.5rem;
}
.custom-btn-size2 {
  font-size: 0.8rem;
  padding: 0.35rem 0.75rem;
}
.btn-width {
  width: 5rem;
}

.user-theme-color {
  color: var(--color-primary-user) !important;
}
.user-theme-color:hover {
  color: var(--color-secondary-user) !important;
}

.user-theme-color-second {
  color: var(--color-primary-user-dark) !important;
}
.project-duration {
  font-size: medium;
  display: block;
  text-align: center;
  padding-bottom: 0.5rem;
}

.project-duration-fields {
  display: flex;
  justify-content: space-around !important;
}
.pink-row {
  background-color: rgba(255, 243, 244, 0.568);
}
.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.nav-link.active {
  background-color: #f6f9fca1;
}

.nav-link.active:before {
  border-left: 2px solid #aaa !important;
}
.user-active-sidebar .nav-link.active:before {
  border-left: 2px solid var(--color-primary-user) !important;
}

.clients-table th:first-child {
  width: 1%;
}
.clients-table th:nth-child(2) {
  width: 14%;
}
.clients-table th:nth-child(3),
.clients-table th:nth-child(5) {
  width: 12%;
}
.clients-table th:nth-child(4) {
  width: 13%;
}
.clients-table th:nth-child(6) {
  width: 3%;
}
.clients-table td:nth-child(6) {
  text-align: center;
}

.list-button > i {
  margin-right: 0.25rem;
}

.project-table-class th:first-child {
  width: 0.1%;
}
.project-table-class th:nth-child(2) {
  width: 14%;
}
.project-table-class th:nth-child(3) {
  width: 13%;
}

.project-table-class th:nth-child(4) {
  width: 14%;
}
.project-table-class th:nth-child(5) {
  width: 5%;
}
.project-table-class th:nth-child(6),
.project-table-class th:nth-child(7),
.project-table-class th:nth-child(8) {
  width: 10%;
}
.project-table-class td:nth-child(8) {
  text-align: center;
}

.invoice-table th:first-child {
  width: 0.1%;
}

.employee-table th:first-child,
.employee-table th:nth-child(7) {
  width: 2%;
}
.employee-table th:nth-child(2),
.employee-table th:nth-child(3) {
  width: 18%;
}
.employee-table th:nth-child(4) {
  width: 30%;
}

.employee-table th:nth-child(5),
.employee-table th:nth-child(6) {
  width: 15%;
}

.employee-table.archived {
  color: #525f7f9d;
}
.employee-table.archived tr th {
  color: #8898aa9d;
  background-color: #f6f9fc9d;
}

.integration-table th:first-child {
  width: 25%;
}
.integration-table th:nth-child(2),
.integration-table th:nth-child(3) {
  width: 10%;
}
.integration-table td:nth-child(3) {
  text-align: end;
}

.disabled-integration-button {
  pointer-events: none;
}

.integration-icon {
  display: flex;
  align-items: center;
}
.integration-icon img {
  margin-right: 0.5rem;
}

.user-role-table th:first-child {
  width: 20%;
}
.user-role-table th:nth-child(2) {
  width: 20%;
}
.user-role-table th:nth-child(3) {
  width: 20%;
}
.user-role-table th:nth-child(4) {
  width: 20%;
}
.user-role-table th:nth-child(5) {
  width: 20%;
}
.user-role-table th:last-child {
  width: 15%;
}
.user-role-table tr > td:last-child {
  padding-left: 3rem;
}

.project-details-table th:first-child {
  width: 5%;
}
.project-details-table th:nth-child(2) {
  width: 8%;
}
.project-details-table th:nth-child(3) {
  width: 6%;
}
.project-details-table th:nth-child(4) {
  width: 1%;
}
.project-details-table th:last-child {
  width: 2%;
}
.project-details-table tr > td:last-child {
  padding-left: 3rem;
}

@media screen and (min-width: 1600px) {
  .project-details-table tr > td:last-child {
    padding-left: 5rem;
  }
}

.dashboard-employee-table th:first-child {
  width: 50%;
}
.dashboard-employee-table th:nth-child(2) {
  width: 25%;
}
.dashboard-employee-table th:last-child {
  width: 25%;
}

.dashboard-project-table th:first-child,
.dashboard-project-table th:nth-child(2) {
  width: 10%;
}
.dashboard-project-table th:nth-child(3) {
  width: 15%;
}
.dashboard-project-table th:last-child {
  width: 0.01%;
}

.custom-breadcrumb-admin.no-button {
  line-height: 2;
}

.custom-text-button,
.custom-text-button:hover,
.custom-text-button:focus {
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
  color: #aaa;
  padding: 0;
}

.custom-parent-toggler input:checked + .custom-child-toggler {
  border-color: #aaa !important;
}
.custom-parent-toggler input:checked + .custom-child-toggler:before {
  background: #aaa !important;
}
.custom-parent-toggler-role {
  height: 1.2rem;
  width: 2.75rem;
}
.custom-parent-toggler-role input:checked + .custom-child-toggler-role {
  border-color: #aaa !important;
}
.custom-parent-toggler-role input:checked + .custom-child-toggler-role:before {
  background: #aaa !important;
}
.custom-child-toggler-role:before {
  width: 15px;
  height: 15px;
  left: 3px;
  right: 3px;
}
.toggled-date,
.toggle-name {
  cursor: pointer;
  user-select: none;
}
.welcome-text {
  font-size: 2.7rem;
  text-shadow: 0px 0px 2px rgb(124, 124, 124);
}
.form-label-text {
  color: #8898aa;
}

.no-user-img {
  height: 36px;
  width: 36px;
}
.lowercase-text {
  text-transform: lowercase;
}
.cursor-normal {
  cursor: auto;
}

.custom-styled-label {
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  .fa {
    color: var(--color--secondary--user);
  }
}
.icon-width {
  width: 42px;
}

.input-not-select::placeholder {
  color: #8898aa;
}

.user-text {
  text-transform: lowercase;
}
.user-text::first-line {
  text-transform: capitalize;
}

.btn-custom-size {
  padding: 0.4rem 1rem;
}

.custom-heading {
  position: relative;
  top: -2rem;
  font-size: 2rem;
  font-family: 'Inter';
  color: #ffffff;
}
.custom-page-item-admin.active .page-link {
  background-color: #aaa;
  border-color: #aaa;
}
.custom-page-item-user.active .page-link {
  background-color: var(--color-primary-user);
  border-color: var(--color-primary-user);
}
.verify-token-spinner {
  width: 2.75rem;
  height: 2.75rem;
  margin: 0.35rem 0;
  border: 0.35rem solid var(--color-secondary-admin);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.verify-token-container {
  margin-top: 0.3rem;
}
.transparent-modal .modal-content {
  background-color: transparent;
}
.verify-modal-content {
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
  border-radius: 2px;
}

.failed-verify-msg {
  text-align: center;
}
.failed-verify-msg i {
  font-size: 3.25rem;
  margin: 0.35rem 0;
}
.failed-verify-msg div {
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
}
.verify-modal-content .div {
  border-radius: 5px;
}

.custom-back-link {
  position: relative;
  top: -3rem;
  padding: 1rem;
  width: fit-content;
  color: #32325d;
}
.custom-back-link:hover {
  cursor: pointer;
  color: #fff;
  -webkit-transform: translateY(-0.1rem);
  transform: translateY(-0.1rem);
}
.custom-breadcrumb-admin {
  font-weight: 600;
  color: #4a4a4a;
  font-family: 'Fira Sans', sans-serif;
}
.custom-breadcrumb-admin span {
  color: var(--color-primary-user);
}
.custom-breadcrumb-admin span:hover {
  color: var(--color-primary-user);
  cursor: pointer;
}
.custom-breadcrumb-user {
  font-weight: 500;
  color: var(--color-primary-user);
}
.custom-breadcrumb-user span {
  color: var(--color-primary-user);
}
.custom-breadcrumb-user span:hover {
  color: var(--color-secondary-user);
  cursor: pointer;
}

.heading-text {
  font-size: 1.625rem;
  padding-bottom: 0.5rem;
}
.custom-disabled {
  cursor: pointer;
  pointer-events: none;
  background-color: #eee !important;
}

select {
  color: #9e9e9e !important;
}
option:not(:first-of-type) {
  color: #40413d !important;
}

input,
label {
  color: #40413d !important;
}

input:placeholder {
  color: #9e9e9e !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9e9e9e !important;
}

::-ms-input-placeholder {
  color: #9e9e9e !important;
}

.main-text-color {
  color: #40413d !important;
}
.minus-margin-left {
  margin-left: -0.5rem;
}
.input-tag-w10 {
  width: 10rem !important;
}

.sidenav-child .nav-item .nav-link {
  font-size: 0.8125rem;
}

.user-project-table th:first-child {
  width: 20%;
}
.user-project-table th:nth-child(2) {
  width: 20%;
}
.user-project-table th:nth-child(3) {
  width: 30%;
}

.user-project-table th:nth-child(4) {
  width: 10%;
}
.user-project-table td:nth-child(4) {
  text-align: right;
}
.user-project-table th:nth-child(5) {
  width: 10%;
}

.password-strength-container {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.password-strength-container {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.bg-gradient-user {
  background-image: url('assets/img/user_header.png') !important;
  background-size: cover;
  -webkit-background-size: cover;
}
.bg-gradient-user-large {
  background-image: url('assets/img/user_header_large.png') !important;
  background-size: cover;
  -webkit-background-size: cover;
}
.bg-gradient-admin {
  background-image: url('assets/img/admin_header.png') !important;
  background-size: cover;
  -webkit-background-size: cover;
}
.bg-gradient-admin-large {
  background-image: url('assets/img/admin_header_large.png') !important;
  background-size: cover;
  -webkit-background-size: cover;
}

.delete-button {
  color: var(--color-warning);
}
.logs-edit-btn {
  color: var(--color-secondary-user);
  font-size: 0.9rem;
}

.archive-toggle {
  color: var(--color-primary-admin);
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.75rem 0 1.75rem;
}
.archive-toggle:hover {
  color: var(--color-secondary-admin);
}

.password-reset-success {
  text-align: left;
  margin-bottom: 2.5rem;
}
.dropdown-logout:active {
  background: var(--gray);
}
.disabled-percentage {
  color: gray;
}
.cursor-pointer {
  cursor: pointer;
}
.delete-div {
  padding: 0.5rem 0.75rem;
}

.user-action-bar {
  padding: 0.25rem 0;
}

.text-admin {
  color: var(--color-primary-user) !important;
}
.text-user {
  color: var(--color-primary-user) !important;
}
.logs-spinner.no-header {
  margin-top: 1.5rem;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1rem;
  height: 1rem;
}

.custom-control {
  padding-left: 5rem;
  min-height: 1.25rem;
}

.pl-pt-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.client-edit-button-child .save-btn {
  margin-right: 0;
}

.spinner-container {
  width: 69.8px;
  text-align: center;
}

.user.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--color-primary-user);
  background-color: var(--color-primary-user);
}

.user.custom-checkbox
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  border-color: var(--color-primary-user);
  background-color: var(--color-primary-user);
}

.project-list.user a {
  color: var(--color-primary-user);
}

.not-found-message {
  font-size: 0.875rem;
}
.spinner-lg {
  display: inline-block;
  width: 20rem;
  height: 20rem;
  vertical-align: text-bottom;
  border: 1.25em solid var(--color-primary-admin);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.text-xlg {
  font-size: 5rem;
  color: #fff;
}
.redirect-header {
  background-color: #fff;
}
.redirect-header center {
  height: 100vh;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-overflow-y {
  height: '75vh';
  overflow-y: 'auto';
}
.custom-invalid-feedback-text-mt-1 {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 300;

  margin-bottom: 0.25rem;
  color: var(--color-warning);
}
.btn-outline-admin {
  color: var(--color-primary-admin);
  border-color: var(--color-primary-admin);
  background-color: transparent;
  background-image: none;
  font-weight: 400;
  &:hover {
    color: #fff;
    background-color: var(--color-primary-admin);
  }
  &.disabled {
    cursor: not-allowed;
  }
}
.color-admin {
  color: var(--color-primary-admin);
}
.sidebar-icon {
  width: 15px;
  height: 15px;
  margin-right: 1.3rem;
}
@keyframes spinner-color {
  100%,
  0% {
    border-color: #008744;
    border-right-color: transparent;
  }
  25% {
    border-color: #0057e7;
    border-right-color: transparent;
  }
  50% {
    border-color: #d62d20;
    border-right-color: transparent;
  }
  75% {
    border-color: #ffa700;
    border-right-color: transparent;
  }
  to {
    transform: rotate(360deg);
  }
}
.google-spinner {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.35em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 1s linear infinite spinner-color;
  transition: all 1s;
}
.user-name {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  text-shadow: 0px 0px 2px rgb(14, 13, 13);
  color: #fff !important;
}
.user-nav-image {
  border-radius: 2rem;
  background-color: #ffd9845c;
  padding: 0.35rem 0.85rem;
  color: #ffff;
  user-select: none;
}
.user-role {
  padding: 0.35rem 0.85rem;
  color: var(--color--primary--user);
  font-weight: 500;
}
@keyframes boxshadow-show {
  0% {
    box-shadow: 0px 0px 0px #ccc;
    transform: translateY(-0px);
  }
  100% {
    box-shadow: 0px 3px 0px #ccc;
    transform: translateY(-3px);
  }
}
@keyframes boxshadow-hide {
  0% {
    box-shadow: 0px 3px 0px #ccc;
    transform: translateY(-3px);
  }
  100% {
    box-shadow: 0px 0px 0px #ccc;
    transform: translateY(-0px);
  }
}
.user-role[aria-label='simple'] {
  box-shadow: 0px 3px 0px #ccc;
  transform: translateY(-5px);
  animation: boxshadow-show 0.15s ease-out;
}
.user-role[aria-label='admin'] {
  box-shadow: 0px 0px 0px #ccc;
  transform: translateY(-0px);
  animation: boxshadow-hide 0.15s ease-out;
}
.text-dark {
  color: var(--color-user-dark);
}
