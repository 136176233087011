// Dropdown
.dropdown {
  position: relative;

  &__btn {
    color: #6f6b64;
    background: var(--button-bg-color);
    padding: 0.5rem 0.75rem;
    font-size: 0.844rem;
    font-family: var(--ff-primary);
    border: 1.125px solid #d3d3d3;
    border-radius: 4.5px;
    display: flex;
    gap: 0.563rem;
    align-items: center;
    min-width: 169.75px;
    justify-content: space-between;
  }

  &__content {
    position: absolute;
    display: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0.5rem 0.688rem;
    margin-top: 0.375rem;
    max-height: 147px;
    background: #fff;
    overflow: auto;
  }

  &__search {
    display: flex;
    gap: 0.376rem;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    width: 147px;
    overflow: hidden;
    padding: 0.5rem 1.125rem 0.5rem 0.713rem;

    input {
      width: 100%;
      border: 0;
      font-size: 0.775rem;
    }
  }
  &__projects {
    display: flex;
    flex-direction: column;
    gap: 1.063rem;
    padding: 0.563rem 0;
    max-height: 200px;

    &--project {
      font-size: 0.75rem;
      margin: 0;
      cursor: pointer;
    }
  }
}

.show {
  display: block;
}

// user status header
.status__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// user status
.status {
  padding: 1.125rem 1.266rem;

  &__header {
    &--title {
      font-size: 1rem;
      margin: 0;
    }
  }

  &__body {
    min-height: 236px;
    margin: 1rem 0;
    display: grid;
    place-items: center;
    gap: 0.625rem;

    &--subtitle {
      font-size: 0.75rem;
      color: #8e8e8e;
    }
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }
}

// user status card
.cardStatus {
  padding: 1.141rem 0.924rem;
  border: 1.125px solid #eeedec;
  border-radius: 11.25px;
  display: flex;
  gap: 13.73px;
  width: 100%;

  &__img {
    width: 84px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 12px;
      margin-top: 8px;
    }

    &--user {
      border: 2px solid #ffc952;
      border-radius: 50%;
      width: 65.25px;
      height: 65.25px;
    }
  }

  &__icons {
    &--blocker,
    &--mood,
    &--tick {
      position: absolute;
    }
    &--blocker {
      top: -1px;
      left: 56px;
    }
    &--mood {
      top: 44px;
      left: 56px;
    }
    &--tick {
      top: 44px;
      left: 7px;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    gap: 0.633rem;
  }

  &__btn {
    border: 1px solid;
    font-size: 0.703rem;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 0.703rem;
    padding: 0.188rem 0.563rem;
  }

  &__btn:last-child {
    margin-bottom: 0;
  }

  &__btns--yesterday {
    color: #64a2ff;
    border-color: #64a2ff;
  }
  &__btns--today {
    color: #42be65;
    border-color: #42be65;
  }
  &__btns--blockers {
    color: #ef5753;
    border-color: #ef5753;
  }

  &__info p {
    font-size: 0.703rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  &__info p:last-child {
    margin-bottom: 0;
  }
}

// pagination
.pagination {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &__numbers {
    display: flex;
    gap: 0.5rem;

    &--number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.688rem;
      border: 0.989561px solid #d3d3d3;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &__icon {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.active {
  border-color: #ffbf32;
}
