.custom-day {
  font-size: 0.8rem;
  font-weight: 400;
  margin-inline: 0.3rem;
  padding-inline: 0.7rem;
  cursor: pointer;
  text-align: center;
}
.custom-day-border {
  padding-inline: 0.8rem;
  border: 1px solid;
  border-radius: 5px;
  width: 60%;
}

.missed {
  border-bottom-color: var(--color-secondary-user-light);
  color: var(--color-secondary-user-light);
}

.logged {
  border-bottom-color: var(--color-primary-user);
  color: var(--color-primary-user);
}
.missing-logs-tooltip {
  font-size: 0.6rem !important;
}
.days {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  padding: 0rem 0.2rem;
}
.current-day {
  border-radius: 1rem;
  background-color: #f2f1ee94;
}
