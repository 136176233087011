@media (min-width: 1365px) {
  .custom-stat-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.custom-stat-card {
  .card-body {
    .card-title {
      font-size: 0.75rem;
      & + div {
        font-size: 0.75rem;
        span:first-child {
          font-size: 0.9rem;
        }
      }
    }
    > p {
      font-size: 0.75rem;
      span:first-child {
        font-size: 0.9rem;
      }
    }
    div:last-child {
      font-size: 0.75rem;
      .logged-by {
        margin-right: 0.5rem;
        color: var(--color-user-dark);
      }
      .avatar-group {
        > span {
          width: 30px;
          height: 30px;
          font-size: 0.9rem;
          z-index: 0;
          &:hover {
            z-index: 3;
          }
        }
        .avatar {
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.custom-stat-card:last-child {
  .card-info-loader {
    margin-top: 0.2rem;
    margin-bottom: 0.75rem;
  }
  .card-employee-logged-loader {
    margin-top: 0.9rem;
  }
  .card-percentage-loader {
    margin-top: 0.839rem;
  }
}
.mb-875 {
  margin-bottom: 0.875rem;
}

.card-info-loader {
  width: 23%;
}
.card-progress-bar-loader {
  width: 88%;
  height: 0.875rem;
}
.card-percentage-loader {
  width: 38%;
}
.card-employee-logged-loader {
  width: 48%;
}

.card-info-loader,
.card-progress-bar-loader,
.card-percentage-loader,
.card-employee-logged-loader {
  height: 0.875rem;
  margin-top: 0.62rem;
  animation: progress;
  animation-iteration-count: infinite;
  animation-duration: 0.6s;
}
.card-info-loader {
  height: 0.9375rem;
  margin-top: 0.518rem;
}
@keyframes progress {
  from {
    background-color: #f6f6ef;
  }
  to {
    background-color: #e8e8e3;
  }
}
.no-hour-mb {
  margin-top: 0.066rem;
  margin-bottom: 0.56rem;
}
.status-progress-bar {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.no-user-logged {
  font-size: 0.9rem;
}
.no-hours-logged {
  margin-bottom: 0.85rem;
  font-size: 0.95rem;
}
.progress-bar-container {
  margin-bottom: 0.15rem;
}

.hours-text {
  opacity: 0.5;
}

.mb-526 {
  margin-bottom: 0.526rem;
}

.no-active-users {
  font-size: 0.95rem;
  margin-bottom: 1.05rem;
}
.mb-06 {
  margin-bottom: 0.6rem;
}
.status-card-time {
  span {
    color: var(--color-user-dark);
  }
}
