.role-span {
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 0.2rem 0.4rem;
  white-space: nowrap;
  line-height: 2rem;
  margin-right: 0.75rem;
}
.client-signup-btn {
  font-weight: 300;
  font-size: 0.9rem;
  font-family: 'Fira Sans';
  color: #fff;
  background-color: var(--color-secondary-user);
  cursor: pointer;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.5rem;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
  &:hover {
    box-shadow: 0px 0px 0px transparent;
  }
}
.signup-error {
  padding-block: 0.25rem;
}
.signup-error-message {
  margin-left: 0.25rem;
}
.client-forgot-password {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffbf32;
  margin-top: 0.25rem;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}
.email-sent-text {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #53514d;
  text-align: center;
}
.client-password-reset-spinner {
  font-size: 1.5rem;
  color: var(--color-secondary-user);
  display: flex;
  justify-content: center;
}
