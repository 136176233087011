.nav__sidebar {
  transform: translate(0) !important;
  height: 100vh;
}

.navbar__collapse {
  position: relative;
  top: 10px;
  background: transparent;
  overflow-x: hidden;
  box-shadow: none !important;
  &::before {
    content: '';
    display: block;
    box-sizing: border-box !important;
  }
}

.width-50 {
  width: 50px;
}

.open_menu {
  width: 10px;
}

@media (max-width: 800px) {
  .remove-events {
    pointer-events: none;
    touch-action: none;
  }
}

@media (min-width: 800px) {
  .width-50 {
    width: unset;
  }
  .navbar__menu {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .navbar__collapse.collapse.show.navbar-collapse {
    width: 120%;
  }
}
@media only screen and (max-width: 1363px) {
  .custom_slide {
    width: 4.25rem !important;
  }
}
