.alert-triangle {
  text-align: center;
  margin: auto;
  padding: 5rem;
  font-family: 'Fira Sans';
  font-style: normal;
  color: #4a4626;
  max-width: 42%;
  .main-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
}
