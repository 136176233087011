.review-input {
  display: flex;
  justify-content: space-between;
  .input-group {
    box-shadow: none;
    .input-group-append {
      height: 32px;
    }
    .input-group-text {
      border: 0.5px solid #d3d3d3;
      z-index: 3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #6f6b64;
      background-color: #fbfbfb;
    }
    .disabled-input-group-text {
      .input-group-text {
        color: #aaaaaa;
        background-color: #f4f4f4;
      }
    }
  }
  .hours-logged-input:first-child {
    margin-right: 2rem;
  }
}
.review-card {
  .review-card-header {
    font-size: 1.0625rem;
    margin-top: 0.2rem;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    > div:first-child {
      padding-inline: 1rem;
    }
  }
  .review-card-body {
    max-height: 60vh;
    overflow: auto;
    position: relative;
  }
  .review-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    span {
      font-weight: 600;
    }
  }
}

.mb--2 {
  margin-bottom: -1.5rem !important;
}
.review-invoice-table {
  font-family: 'Fira Sans';
  border-collapse: collapse;
  position: relative;
  thead {
    th {
      background: #fbfbfb;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #b6b6b6;
      position: sticky;
      top: -1px;
      z-index: 5;
      &:first-child {
        width: 1%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 40%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 2%;
      }
    }
  }
  tbody {
    td {
      padding-block: 1rem;
      border-color: transparent;
      color: #6f6b64;
      input {
        border: 0.5px solid #d3d3d3;
        box-sizing: border-box;
        border-radius: 5px;
        color: #6f6b64 !important;
        height: 32px;
        &:disabled {
          color: #aaaaaa !important;
          background-color: #f4f4f4;
        }
      }
    }
    .disabled-td {
      color: #aaaaaa;
    }
  }
  .review-input {
    .hours-logged-input {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
}
.review-invoice-buttons {
  display: flex;
  button {
    background: #ffffff;
    border: 1px solid #e7e8ea;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    padding: 0.5rem 1rem;
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #303f40;
    &:first-child {
      margin-right: 1rem;
    }
    &:hover {
      background: #fafafa;
    }
    &:disabled {
      background-color: #f4f4f4;
      cursor: not-allowed;
    }
    &.downloading-button {
      background-color: #e7e8ea;
    }
    img {
      margin-right: 0.75rem;
      margin-top: -0.1rem;
    }
  }
  .active-button {
    background: #e7e8ea;
    &:hover {
      background: #e7e8ea;
    }
  }
}
.invoice-final-output {
  font-family: 'Fira Sans';
  margin-right: 5rem;
  > div:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #6f6b64;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    > div:first-child {
      margin-right: 2rem;
    }
  }
  span {
    background: #f4f4f4;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6f6b64;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.hours-allowed-btn {
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 5px;
  padding-block: 0.25rem;
  display: flex;
  margin-left: 1rem;
  .action-icons {
    border-left: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
  }
  span {
    padding-inline: 0.75rem;
    padding-block: 0.15rem;

    text-align: center;
    color: #6f6b64 !important;
  }
  i {
    font-size: 0.8rem;
    padding: 0.25rem;

    text-align: center;
  }
  input {
    padding: 0.1rem 0.25rem;
    margin: 0.1rem 0.25rem;
    background-color: transparent;
    border: none;
    width: 2rem;
  }
}
.retainer-checkbox {
  font-family: 'Fira Sans';
  display: flex;
  align-items: center;
  > div {
    margin-left: 1rem;
  }
  font-size: 0.95rem;

  .template-option {
    border-radius: 50px;
    border: 1px solid #eeedec;
    padding: 0.25rem 1rem;
    cursor: pointer;
    &-selected {
      @extend .template-option;
      background: #ffefcc;
      border: 1px solid #f8d891;
    }
  }
  .custom-control-label::before,
  .custom-control-label::after {
    margin-left: 1.5rem;
  }
}
