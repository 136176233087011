.retainer-form {
  padding: 2rem 1rem;
  font-family: 'Fira Sans';
  width: 100%;
  label {
    font-weight: 500;
  }
  input {
    border: 0.5px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 5px;
    color: #6f6b64 !important;
    height: 32px;
  }
}
.retainer-table {
  width: 100%;
  font-family: 'Fira Sans';
  padding: 5rem;

  thead {
    th {
      background: #fbfbfb;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #b6b6b6;
      padding-inline: 1.5rem !important;
      padding-block: 0.75rem !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-block: 1px solid #e9ecef;
      -webkit-user-select: none;
      user-select: none;
    }
  }
  tbody tr {
    &:first-child {
      td {
        padding: 1rem;
      }
    }
    &:nth-child(2) {
      td {
        padding: 1rem;
        padding-top: 0;
      }
    }
  }
}
