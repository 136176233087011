.welcome-sub-text-container {
  border: 0.5px solid var(--color-primary-user-dark);
  padding-bottom: 0;
  border-radius: 5px;
  position: relative;
}
.welcome-sub-text-container .mt-0 {
  padding: 1rem;
  padding-right: 1.3rem;
  padding-bottom: 0;
}
.welcome-sub-text-container .fa-times {
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.flex-basis-80 {
  flex-basis: 80%;
}
.flex-basis-20 {
  flex-basis: 20%;
}
.user-welcome-container {
  display: flex;
  align-items: center;
}
.welcome-text {
  margin: 0.75rem 0 0.7rem;
  font-weight: 400;
  color: #fff;
}
.user-welcome-container {
  color: var(--color-primary-user-dark);
}
.no-greeting-text {
  height: 5.45rem;
}
.export-icon {
  height: 1.1rem;
  padding-block: 0.1rem;
}
#selectViewUserRole {
  padding-right: 0%;
}
.header {
  padding-bottom: 3.5rem !important;
}
