.missed-logs-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-primary-user);
  p {
    margin-bottom: 0;
  }
  p.mb-0 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 0.4rem;
    span {
      font-style: italic;
      border-bottom: 2px solid var(--color-primary-user);
      transition: all 0.3s ease;
      cursor: pointer;
      letter-spacing: 0.3px;
      &:hover {
        background-color: var(--color-primary-user);
        color: white;
        padding-inline: 0.25rem;
        border-radius: 2px;
      }
    }
  }
}
.log-time-btn {
  border: 1px solid var(--color-secondary-user);
  border-radius: 1rem;
  padding-block: 3px;
  @media only screen and (max-width: 1363.98px) {
    padding-block: 2.5px;
  }
  color: #fff;
  background-color: var(--color-secondary-user);
  font-weight: 600;
  width: 6.25rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;
  user-select: none;
  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  }
}
.start-timer-btn {
  border: 1px solid var(--color-primary-user);
  margin: 0 auto;
  font-size: 0.7rem;
  padding-inline: 0.6rem;
  padding-block: 0.4rem;
  &:hover {
    background-color: var(--color-primary-user);
    border: 1px solid var(--color-primary-user);
    color: #fff;
  }
}
.disabled-btn {
  color: #f1f1f1;
  pointer-events: none;
}

.projects-header {
  padding: 10px 1.5rem;
}

.view-logs-btn {
  border: 1px solid var(--color-primary-user);
  border-radius: 1rem;
  padding-block: 3px;
  @media only screen and (max-width: 1363.98px) {
    padding-block: 2.5px;
  }
  color: var(--color-primary-user);
  font-weight: 600;
  width: 6.25rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;
  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  }
}
.user-dashboard-card {
  padding: 0.375rem 0.6rem;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: fit-content;
  .apply-leave {
    display: flex;
    cursor: pointer;
    span {
      color: var(--color--primary--user);
      font-size: 0.75rem;
    }
    img {
      width: 19px;
      height: 19px;
      margin-right: 0.563rem;
    }
  }
  &-container {
    display: flex;
    padding: 0.5rem 0.75rem;
    border: 1px solid #eeedec;
    border-radius: 6.05825px;
    background: #ffffff;
    border: 1px solid #eeedec;
    border-radius: 6.05825px;
  }
  .clock-icon {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #fff7e4;
    display: grid;
    place-items: center;
    margin-right: 0.3rem;
    i {
      color: #ffad32;
      font-size: 0.8rem;
    }
  }
  .hours-logged {
    &.day {
      width: 3rem;
    }
    &.week {
      width: 3.5rem;
    }
    h3 {
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      color: var(--color-primary-user);
    }
    p {
      font-size: 0.7rem;
    }
    span {
      font-size: 0.65rem;
    }
  }
  h3 {
    font-size: 0.65rem;
    color: #898989;
    font-weight: 100;
    margin: 0;
    font-style: italic;
  }
  p,
  .no-hours {
    color: #ccc;
  }
  p {
    color: var(--clr-dark-gray);
    font-size: 0.75rem;
    font-family: var(--ff-primary);
    margin: 0;
    font-weight: 400 !important;
  }
  .check-in {
    display: flex;
    align-items: center;
    color: var(--color-primary-user);
    padding: 0 0.625rem;
    &-btn {
      font-weight: 300;
      color: #fff;
      background-color: var(--color-secondary-user);
      cursor: pointer;
      user-select: none;
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      padding: 0.25rem;
      border-radius: 10px;
      align-items: center;
      width: 100px;
      .duration {
        font-family: 'Helvetica Neue', Helvetica, Arial;
      }
    }
    &-value {
      margin-left: 0.35rem;
      padding-left: 0.35rem;
      color: var(--color-primary-user);
      margin-left: 0.5rem;
      width: 7.5rem;
      &-time {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        i {
          font-size: 0.475rem;
          margin-right: 0.625rem;
        }
        span {
          color: #ccc;
          font-family: var(--ff-primary);
          font-size: 0.625rem;
        }
        .checked {
          color: var(--color-primary-user);
          padding: 2px;
          border: 2px solid transparent;
        }
        .check-in-edit-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 1.375rem;
          .time-input {
            box-shadow: 0px 0px 3px #ccc;
            border-radius: 3px;
            border: 1px solid transparent;
            .checkin-input {
              border-color: transparent;
              border-radius: 5px;
              font-size: 0.72rem;
              padding: 0;
              margin: 0;
              line-height: 1;
            }
          }
          i {
            font-size: 0.85rem;
          }
        }

        &-loader {
          height: 0.875rem;
          margin-block: 0.25rem;
          margin-inline: 4px;
          width: 3.75rem;
          -webkit-animation: progress;
          animation: progress;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
        }
      }
    }
    .check-in-work {
      margin-right: 1rem;
      &.disabled {
        background-color: transparent !important;
      }
      .unchecked-box {
        background-color: #d5d5d52e !important;
        border-color: #b5b5b55e !important;
      }
    }
    .active {
      color: var(--color-secondary-user);
      cursor: pointer;
    }
    .neutral {
      color: var(--color-primary-user);
      pointer-events: none;
    }
    .checkin-edit-btn {
      margin-left: -0.783rem;
      font-size: 0.9rem;
      width: 15px;
    }
  }
}

.user-dashboard-card-loader {
  height: 0.85rem;
  margin-block: 0.151rem 0.2rem;
  -webkit-animation: progress;
  animation: progress;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  width: 2.5rem;
  text-align: center;
  margin-inline: 0.5rem;
}
.align-item-center {
  align-items: center;
}

.timer {
  font-size: 2rem;
  font-weight: 600;
  background-color: #fdc13f;
  color: #fff;
  width: 12rem;
  border-radius: 50%;
  padding: 9rem;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
}
.user-dashboard-button,
.user-dashboard-checkin-button {
  transition: top 2s ease 2s;
  padding: 0.5rem 0.75rem;
}

.user-dashboard-button {
  border: 1px solid #e7e8ea;
}

.user-dashboard-button:hover {
  top: -5px !important;
}

.update-time-popover {
  padding: 0.5rem;
}

.dashboard__header--btns {
  border-right: 0.3px solid #e7e8ea;
  gap: 0.625rem;
  padding-left: 0.625rem;
}
