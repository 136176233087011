.auth-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 3rem;
  div {
    margin-inline: 1rem;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #53514d;
  }
}
.google-login-button {
  background: #ffffff;
  font-family: 'Fira Sans' !important;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  border: 0.5px solid #d3d3d3 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  max-width: 20rem;
  padding: 0.6rem !important;
  span {
    font-weight: 400 !important;
    text-align: left !important;
  }
}
