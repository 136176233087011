.confirmation-popover {
  .arrow {
    display: none;
  }
  .popover-inner {
    padding: 0.85rem;
  }
  .popover-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0.5rem 0.95rem 0rem 0.95rem;
    img {
      width: 22px;
      margin-right: 0.5rem;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #53514d;
      font-family: 'Fira Sans', sans-serif;
    }
  }
  .popover-body {
    padding: 0rem 0.5rem 0.95rem 0.5rem;
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #53514d;
      text-align: center;
      padding-block: 1.25rem;
      font-family: 'Fira Sans', sans-serif;
    }
    .popover-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Fira Sans', sans-serif;
      button {
        display: flex;
        padding: 0.5rem 1.75rem;
        font-family: 'Fira Sans';
        font-size: 12px;
        line-height: 14px;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 400;
      }
      button:first-child {
        background: #ffffff;
        color: #303f40;
        border: 1px solid #8d8c88;
      }
      button:nth-child(2) {
        background: #ffc952;
        color: #fff;
        border: 1px solid #ffc952;
      }
    }
  }
}
