.billing-information-preview {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  font-family: 'sanFranciso', sans-serif;
  .invoice-icon-container {
    text-align: right;
  }
  .invoice-text {
    font-size: 1.5rem;
    line-height: 18px;
    letter-spacing: 0.27em;
  }
  .invoice-name {
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1px;
  }
  .billing-to-text {
    font-weight: 500;
    font-size: 1.25rem;
    margin-block: 2rem 0.5rem;

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
  }
  .billing-key {
    font-weight: 500;
  }
  .billing-key,
  .billing-value {
    font-size: 0.8rem;
    line-height: 1.75rem;
  }
}
.add-billing-button {
  display: inline-block;
  user-select: none;
}
@media only screen and (max-width: 1363.98px) {
  .remove-button {
    font-size: 0.7rem;
  }
}

.roles {
  &.invalid-form {
    .tag {
      border-color: var(--color-warning) !important;
    }
  }
  .form-control {
    height: auto;
    .input-tags {
      border: 0 !important;
      .badge {
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        background: #fbfbfb;
      }
    }
  }
}

.tag {
  user-select: none;
  padding-block: 0.25rem;
  input[type='text'] {
    box-shadow: none !important;
    border-color: transparent !important;
    margin-block: 3px;
  }
}
.react-tagsinput-tag {
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  margin-right: 0.5rem;
  font-size: 12px;
  white-space: nowrap;
}
.react-tagsinput-remove::before {
  content: 'x';
  margin-left: 0.5rem;
  margin-bottom: 2px;
  cursor: pointer;
}
