$expansion: -30px;
.custom-timeline-container {
  height: 350px;
  max-width: 100%;
  position: relative;
  .loading {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: $expansion;
    right: $expansion;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timeline-header {
    position: absolute;
    color: #8898b2;
    font-size: 10px;
    left: 16px;
    z-index: 4;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      z-index: 4;
      height: 6px;
      width: 6px;
      left: -10px;
      top: 5px;
      border-radius: 50%;
    }
    &.top {
      top: 20px;
      &::before {
        background: #42be65;
      }
    }
    &.bottom {
      bottom: 38px;
      &::before {
        background: #fa4d56;
      }
    }
  }

  .timeline {
    position: relative;
    height: 100%;
    margin: 0 $expansion;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #e9ecef transparent;

    &:not(:active) {
      cursor: default !important;
    }
    &::-webkit-scrollbar {
      width: 1em;
      height: 0.5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e9ecef;
      border-radius: 5px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 20px;
    }
    &:before {
      left: 0;
      border: none;
    }
    &:after {
      right: 0;
    }
  }

  .timeline-horizontal {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 150px;
    height: 40px;
    background-color: #f6f9fc;
    transition: transform 0.4s;
    user-select: none;
  }

  .timeline-time {
    position: absolute;
    z-index: 4;
    min-height: 40px;
    border-left: 1px dashed #8898b2;
    .text {
      text-align: center;
      font-size: 12px;
      color: #8898b2;
      background-color: #fff;
      text-transform: uppercase;
      padding-inline: 6px;
      border-radius: 4px;
      border: 1px solid #8898b2;
      display: inline-block;
      transform: translate(25%, 32%);
      &.small-chunk {
        transform: translate(10%, 32%);
      }
    }
  }
  .timeline-check {
    position: absolute;
    transform: translateX(50%);
    z-index: 2;
    min-height: 40px;
    &:hover {
      z-index: 3;
    }
    .avatar {
      position: absolute;
      border: 2px solid #ffffff;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
    .text-avatar {
      font-size: 13px;
      user-select: none;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 16px;
      height: 7px;
      transform: translateX(-42%);
      width: 7px;
      background-color: #8898b2;
      border-radius: 50%;
    }
    &::before {
      content: '';
      position: absolute;
      border-right: 1px dashed #8898b2;
    }

    &.in {
      .avatar {
        transform: translate(-48%, -87px);
      }
      &::before {
        bottom: 21px;
        height: 80px;
      }
    }
    &.out {
      &::before {
        top: 21px;
        height: 80px;
      }
      .avatar {
        transform: translate(-48%, 87px);
      }
    }
    .multiple-user {
      cursor: pointer;
      user-select: none;
      z-index: 3;
    }
    .background {
      &-in {
        background-color: #42be65;
      }
      &-out {
        background-color: #f33a5f;
      }
    }
  }
}

.fade {
  transition: opacity 0s linear;
}
.custom-btn-sm {
  padding: 3px 5px;
  font-size: 10px;
}
.popover-users {
  &.info {
    .popover-body {
      position: relative;
      .info-card-btn,
      .back-button {
        position: absolute;
        top: 5px;
        font-size: 12px;
        cursor: pointer;
        color: #6f6b64;
      }
      .info-card-btn {
        right: 10px;
      }
      .back-button {
        left: 10px;
      }
    }
  }
  &.multiple {
    border-radius: 50px;
    background-color: #fff;
    box-shadow: none;
    border: 0.5px solid #d3d3d3;
    &.not-round {
      border-radius: 10px;
    }
    .popover-body {
      padding: 0;
      .avatar-group {
        border: 3px solid #fff;
        flex-flow: row wrap;
        max-width: 210px;
        border-radius: 25px;
      }
    }
  }
}
.checkin-wrapper-single-card {
  text-align: center;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
  .user-data {
    width: 12.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .work-from-icon {
      font-size: 1rem;
      margin-left: 0.3rem;
    }

    .name {
      margin-block: 0.6rem 0.1rem;
      font-size: 0.85rem;
    }
    p {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      color: var(--color-user-dark);
      i {
        cursor: unset;
      }
      .working {
        color: #2dce89;
        font-weight: bold;
      }
      span {
        color: var(--color-primary-user);
      }
      margin: 0;
    }
  }
  div {
    img {
      border-radius: 50px;
      margin-left: 0.5rem;
    }
    p {
      font-size: 0.85rem;
      color: #525f7f;
      margin: 0;
    }
    h3 {
      color: #212529;
    }
  }
}
.timeline-hours-btn {
  .fa-history {
    color: var(--color-secondary-user);
  }
  .dropdown {
    .toggle-btn {
      padding: 0.5rem 0.75rem;
      background: #ffffff;
      border: 0.5px solid #d3d3d3;
      box-sizing: border-box;
      border-radius: 7px;
      box-shadow: none;
      font-size: 0.8rem;
      font-weight: 400;
      min-width: 170px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fa-angle-down {
        transition: all 0.15s;
      }
      &[aria-expanded='true'] {
        .fa-angle-down {
          transform: rotate(-90deg);
        }
      }
      &[aria-expanded='false'] {
        .fa-angle-down {
          transform: rotate(0deg);
        }
      }
    }
  }
}

$time: 5s;
$border: hsla(217, 21%, 62%, 0.5);
$border-shadow: rgba(136, 152, 178, 0.3);
$background-hour: rgba(253, 193, 63, 0.8);
$background: #f6f9fc;

.hourglass {
  animation: flip $time ease-in-out infinite;
  border-bottom: solid 0.5vmin $border;
  border-top: solid 0.5vmin $border;
  left: 50%;
  margin-left: -6vmin;
  margin-top: -5vmin;
  padding: 0 0.5vmin;
  position: absolute;
  top: 50%;
  .top,
  .bottom {
    background-color: $background;
    box-shadow: 0 0 1vmin 1vmin $border-shadow inset;
    height: 3vmin;
    overflow: hidden;
    position: relative;
    width: 3vmin;
  }
  .top {
    border-radius: 0 0 50% 50%;
    &:before {
      animation: top $time linear infinite;
      background-color: $background-hour;
      border-radius: 50%;
      content: '';
      display: block;
      height: 3vmin;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(50%);
      width: 3vmin;
    }
    &:after {
      animation: top-drip $time linear infinite;
      background-color: $background-hour;
      content: '';
      display: block;
      height: 100%;
      left: 45%;
      position: absolute;
      top: 0;
      transform: translateY(100%);
      width: 10%;
    }
  }
  .bottom {
    border-radius: 50% 50% 0 0;
    &:before {
      animation: bottom $time linear infinite;
      background-color: $background-hour;

      border-radius: 50%;
      content: '';
      display: block;
      height: 3vmin;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(100%);
      width: 3vmin;
    }
    &:after {
      animation: bottom-drip $time linear infinite;
      background-color: $background-hour;
      content: '';
      display: block;
      height: 100%;
      left: 45%;
      position: absolute;
      top: 0;
      width: 10%;
    }
  }
}

@keyframes flip {
  0%,
  45% {
    transform: rotate(0);
  }
  50%,
  95% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bottom {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(50%);
  }
  51% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes top {
  0% {
    transform: translateY(50%);
  }
  50% {
    transform: translateY(100%);
  }
  51% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes bottom-drip {
  0% {
    left: 45%;
    transform: translateY(-100%);
    width: 10%;
  }
  5% {
    transform: translateY(0);
  }
  45%,
  100% {
    left: 50%;
    transform: translateY(0);
    width: 0;
  }
}

@keyframes top-drip {
  0%,
  50% {
    left: 45%;
    transform: translateY(100%);
    width: 10%;
  }
  55% {
    left: 45%;
    transform: translateY(0);
    width: 10%;
  }
  100% {
    left: 50%;
    transform: translateY(0);
    width: 0;
  }
}
.black-n-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
