.custom-action-button {
  cursor: pointer;
  display: flex;
  transition: all 0.1s;
  padding: 0.35rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0.025em;
  margin-right: 1.5rem;
  user-select: none;
  box-shadow: 0px 0px 3px 1px #00000010;

  border: 1px solid transparent;
  img,
  i {
    margin-right: 0.45rem;
  }
  div {
    color: var(--button-text-color);
  }
  &:hover {
    background-color: #00000003;
    transform: translateY(-1px);
  }

  &:active {
    background-color: #00000003;
  }
}
.disabled {
  pointer-events: none !important;
  background-color: #f6f9fc9d !important;
  color: #ccc !important;
  div,
  span {
    color: #ccc !important;
  }
}
.view-archived-button {
  font-weight: 600;
  cursor: pointer;
  color: var(--color-primary-user);
  border: 1px solid var(--color-primary-user);
  font-size: 0.85rem;
  padding: 0.25rem 1.75rem;
  transition: all 0.15s;
  background-color: #fff;
  margin-block: 0.5rem 0.25rem;
}
.view-archived-button:hover {
  background-color: var(--color-secondary-user);
  border-color: var(--color-secondary-user);
  color: #fff;
  display: flex;
  transform: translateY(-1px);
}

.modal-action-button {
  margin-left: 1.5rem;
}

.workspace-heading {
  width: 6.25rem;
}

.archived-user-modal {
  .modal-footer {
    padding: 0rem;
  }
}
