.check-in-btn,
.active-check-in-btn {
  border-color: var(--button-text-color);
  border-radius: 4px !important;
  box-shadow: 0px 0px 0px transparent;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  color: #53514d !important;
  -webkit-user-select: none;
  user-select: none;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  align-items: center;
  width: 100px;
  cursor: pointer;
  img {
    margin-right: 0.3rem;
  }
}
.check-in-btn {
  background-color: var(--color-secondary-user) !important;
}
.active-check-in-btn {
  background-color: #f3f3f3;
}

.dashboard__header {
  &--subtitle {
    color: var(--clr-light-gray);
    font-family: 'Fira Sans', sans-serif;
  }

  &--subtitle {
    font-size: 0.625rem;
    font-weight: 400;
  }

  &--card {
    margin-right: 15px;
  }

  &--time {
    padding: 3px;
  }

  &--editIcon {
    margin-left: -0.783rem;
  }
}
