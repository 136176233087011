.error-message {
  color: var(--color-warning);
}
.cursor-pointer {
  cursor: pointer;
}
.hidden-class {
  visibility: hidden;
}
.development-text {
  font-size: 1rem;
  font-weight: 400;
  color: #aaa;
  text-align: center;
}
.edit-color {
  i:hover {
    color: $base-color;
  }
}
.padding-inline-half {
  padding-inline: 0.5rem;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

// styling for all the headings
.title {
  font-size: 1rem;
  color: #53514d;
  font-weight: 500;
  line-height: 19px;
  font-family: 'Fira Sans', sans-serif;
  text-transform: capitalize;
}
