.profile-card-body {
  min-height: 75vh;
}

.change-display-name {
  font-size: 0.75rem;
  padding-top: 0.5rem;
  cursor: pointer;
}
.update-password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .modal-unfilled {
    box-shadow: none;
  }
}
.forget-password-text {
  font-size: 0.85rem;
  color: var(--color-primary-admin);
  border-bottom: 1px solid transparent;
}
.forget-password-text:hover {
  border-bottom: 1px solid var(--color-primary-admin);
}
.change-password-heading {
  font-weight: 500;
  color: #32325d;
}
.navbar-brand-button {
  padding-bottom: 1rem;
  line-height: 3;
  color: #32325d;
  span {
    margin-left: 0.65rem;
  }
}
.save-name-btn {
  position: absolute;
  left: 0.25rem;
  bottom: -1.35rem;
  font-size: 0.75rem;
}
.email-warning {
  font-size: 0.75rem;
  color: #95a5a6;
  font-weight: 300;
  margin-top: 0.15rem;
}
