@mixin active-view {
  color: #fff;
  background-color: var(--color-primary-user);
}
.view-selector {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  border-radius: 5px;
  div {
    padding-block: 0.65rem;
    color: var(--color-primary-user);
    cursor: pointer;
    width: 33.334%;
    text-align: center;
  }
  div:hover {
    background-color: var(--color-secondary-user);
    color: #fff;
  }
  .list-view {
    @include active-view();
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .grid-view {
    @include active-view();
  }
  .calendar-view {
    @include active-view();
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .admin-view-background {
    background-color: #ffc952;
  }
  .admin-view {
    color: #ffc952;
  }
}
