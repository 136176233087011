.input-range {
  margin: 1.1rem 0;
}
.input-range__label {
  font-family: 'sanFranciso', sans-serif;
}
.input-range__slider-container
  .input-range__label
  .input-range__label-container {
  top: -0.2rem !important;
}
.input-range__slider-container:last-child
  .input-range__label
  .input-range__label-container {
  left: 0rem;
}
.input-range__slider-container:nth-last-child(2)
  .input-range__label
  .input-range__label-container {
  left: -3.3rem;
}

.input-range__label--min {
  top: -0.5rem;
  left: -2.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.input-range__label--max {
  margin-right: 0.5rem;
  top: -0.5rem;
  right: -2.7rem;
}
.input-range--disabled .input-range__track--active {
  background: #aaa !important;
}
.input-range__track--active,
.input-range__slider {
  background: var(--color-secondary-user);
  border-color: var(--color-secondary-user);
}

.slider-about-label {
  position: absolute;
  color: rgb(104, 102, 102);
  font-size: small;
  top: 2rem;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
}
.delete-slider {
  color: #b32d12 !important;
}
.slider-manipulate button,
.slider-manipulate button:hover,
.slider-manipulate button:disabled,
.slider-manipulate button:active {
  position: relative;
  padding: 0.2rem;
  margin: auto;
  margin-left: 4.5rem;
  top: 0.3rem;
  box-shadow: none;
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  transition: box-shadow 1s ease-in-out;
  border-radius: 5px;
}
.slider-manipulate button:disabled {
  color: #aaa;
}
.slider-manipulate button:hover {
  box-shadow: 1px 2px 1px 1px #ccc;
  padding-top: 0.3rem;
}
.add-slider {
  color: #12b383 !important;
}
