.sidenav-toggler-inner,
.sidenav-toggler-line {
  width: 18px;
  transition: all 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidenav-toggler-inner,
  .sidenav-toggler-line {
    transition: none;
  }
}

.sidenav-toggler-inner {
  position: relative;
}
.sidenav-toggler-inner:before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  left: -11px;
  top: -14px;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
@media (prefers-reduced-motion: reduce) {
  .sidenav-toggler-inner:before {
    transition: none;
  }
}

.sidenav-toggler-line {
  height: 2px;
  background-color: #172b4d;
  display: block;
  position: relative;
}
.sidenav-toggler-line:not(:last-child) {
  margin-bottom: 3px;
}

.sidenav-toggler-dark .sidenav-toggler-line {
  background-color: #fff;
}

.sidenav-toggler {
  cursor: pointer;
}
.sidenav-toggler.active .sidenav-toggler-inner:before {
  transform: scale(1);
}
.sidenav-toggler.active .sidenav-toggler-line:first-child {
  width: 13px;
  transform: translateX(5px);
}
.sidenav-toggler.active .sidenav-toggler-line:last-child {
  width: 13px;
  transform: translateX(5px);
}
// Sidenav toggler

.sidenav-toggler-inner,
.sidenav-toggler-line {
  width: 18px;
  transition: all 0.15s ease;
}

.sidenav-toggler-inner {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: -11px;
    top: -14px;
    // background-color: rgba($primary, .15);
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.sidenav-toggler-line {
  height: 2px;
  background-color: theme-color('default');
  display: block;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
}

.sidenav-toggler-dark {
  .sidenav-toggler-line {
    background-color: white;
  }
}

.sidenav-toggler {
  cursor: pointer;

  &.active {
    .sidenav-toggler-inner {
      &:before {
        transform: scale(1);
      }
    }

    .sidenav-toggler-line {
      background-color: theme-color('primary]');

      &:first-child {
        width: 13px;
        transform: translateX(5px);
      }

      &:last-child {
        width: 13px;
        transform: translateX(5px);
      }
    }
  }
}

.g-sidenav-pinned .sidenav {
  max-width: 250px !important;
}
.g-sidenav-pinned .sidenav .navbar-brand,
.g-sidenav-pinned .sidenav .navbar-heading {
  display: block;
}

.g-sidenav-hidden:not(.g-sidenav-show)
  .sidenav
  .navbar-nav
  > .nav-item
  > .nav-link:after {
  content: '';
}
.g-sidenav-hidden:not(.g-sidenav-show)
  .sidenav
  .nav-item
  .collapse
  .sidenav-normal {
  display: none !important;
}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-link-text {
  opacity: 0;
}

.g-sidenav-hidden:not(.g-sidenav-show) {
  .sidenav {
    .navbar-nav > .nav-item {
      > .nav-link {
        &:after {
          content: '';
        }
      }
    }

    .nav-item .collapse .sidenav-normal {
      display: none !important;
    }

    .nav-link-text {
      opacity: 0;
    }
  }
}
.g-sidenav-hidden.g-sidenav-show {
  .sidenav .nav-link-text {
    opacity: 1;
    transition: all 0.25s;
  }
}

.pd-block-16 {
  padding-block: 30px;
}

.sidenav {
  z-index: 1050;
  transition: all 0.4s ease;
}
.navbar-vertical.navbar-expand-xs + .main-content {
  margin-left: 62px;
}

.sidenav + .main-content {
  transition: all 0.4s ease;
}

.navbar-vertical.navbar-expand-xs
  .navbar-nav
  .nav
  .nav-link
  span.sidenav-mini-icon {
  width: 34px;
  text-align: center;
  transition: all 0.1s ease 0s;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 15px;
}
.sidenav-normal {
  margin-left: 3.4rem;
}

.manager-console {
  padding-bottom: 1rem;
  cursor: pointer;
  white-space: nowrap;
}
.g-sidenav-hidden {
  .sidenav {
    overflow-y: unset;
  }
}

.scrollbar-inner.admin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1199.98px) {
  .sidenav {
    transform: translateX(-62px);
  }
  .sidenav.fixed-left + .main-content {
    margin-left: 0 !important;
  }
  .g-sidenav-pinned .sidenav {
    transform: translateX(0);
  }
}

.g-sidenav-pinned {
  .sidenav {
    @media (min-width: 1199.98px) {
      &.fixed-left + .main-content {
        margin-left: 250px;
      }
      &.fixed-right + .main-content {
        margin-right: 250px;
      }
    }
  }
}
@media (min-width: 1199.98px) {
  .g-sidenav-pinned .sidenav + .main-content {
    margin-left: 250px;
  }
  .sidenav:hover {
    max-width: 250px;
  }
  .g-sidenav-pinned .sidenav.fixed-left + .main-content {
    margin-left: 250px;
  }
  .g-sidenav-pinned .sidenav.fixed-right + .main-content {
    margin-right: 250px;
  }
}
