.integration-card-body {
  span {
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  p {
    font-size: 0.75rem;
    margin: 0;
  }
}

.integration-card-container {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  column-gap: 2.6%;
  row-gap: 2rem;
  padding-bottom: 2rem;
  color: var(--color-user-dark);
}
.integration-connect-button {
  align-items: baseline;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
.channels-table {
  color: black;
}
.channels-table:last-child {
  border-bottom: 0px;
}
.channels-modal {
  justify-content: center;
  .modal-content {
    width: 40vw;
  }
  .modal-content .modal-body {
    padding: 0rem;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    max-height: 69.5vh;
    overflow-y: auto;
  }
}
.integration-outline-btn {
  color: #303f40;
  font-weight: 300;
  padding-inline: 1.75rem 1.75rem;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.05);
  &:focus {
    box-shadow: 1px 1px 2.5px 1px rgba(0, 0, 0, 0.08);
  }
  img {
    margin-left: 3px;
    margin-bottom: 2px;
  }
}
.custom-disabled-btn {
  pointer-events: none;
  cursor: pointer;
  color: #9faeae;
}
.redirect-spinner-modal {
  justify-content: center;
}
.redirect-spinner-modal .modal-content {
  width: 20%;
}
.channels-modal {
  .modal-app-logo {
    margin-right: 4rem;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.38rem;
      font-weight: 400;
      font-size: 0.85rem;
    }
  }
  .modal-header {
    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
.channels-table {
  margin-bottom: 0;

  td:last-child {
    text-align: right;
  }
}
.custom-toggle-loader {
  height: 1.175rem;
  animation: progress;
  animation-iteration-count: infinite;
  animation-duration: 0.6s;
  width: 14%;
  margin: 0.5rem;
  border-radius: 20px;
}
.notification-dropdown-container {
  color: var(--color-user-dark);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
  background: #ffffff;

  border-radius: 10px;
  margin-bottom: 1rem;
}
.transform-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
