.display-flex {
  display: flex;
  align-items: center;
}
.table-container-header {
  @extend .display-flex;
  justify-content: space-between;
  width: 100%;
  .custom-action-card {
    @extend .display-flex;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    transition: box-shadow 0.15s ease;
    padding: 0.6rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    margin-left: 1rem;
    cursor: pointer;
    user-select: none;
    color: var(--color-user-dark);
    i,
    img {
      margin-right: 0.6rem;
    }
    div {
      display: flex;
    }
  }
}
.date-input-container {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
.date-input-container:first-child {
  .form-control,
  .date-text {
    margin-bottom: 0.5rem;
  }
}
.visibility-hidden {
  visibility: hidden;
}

.table-container {
  &-select-group {
    display: flex;
    padding-top: 1rem;
    padding-left: 1rem;
    .form-group {
      margin-bottom: 0px !important;
    }
  }
  &-heading-select {
    flex-basis: 67%;
  }
  &-dates {
    flex-basis: 28%;
    font-size: 0.875rem;
  }
  &-add-view {
    padding-right: 1rem;
    .select-user-wrapper {
      width: max-content;
    }
  }
}
.add-log-button {
  width: 100%;
  &-container {
    margin-right: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.delete-icon {
  color: #e55c5c;
}
.date-text {
  width: 3rem;
}

.disabled.custom-action-card {
  cursor: not-allowed;
  color: #525f7f8c;
  a {
    cursor: not-allowed;
  }
}
.custom-action-card {
  &:not(:disabled) {
    &:hover {
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
      background-color: #00000003;
    }
    &:active {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      background-color: #00000002;
    }
  }
  a {
    color: var(--color-user-dark);
  }
}
.invoice-spinner {
  margin-left: 3rem;
}
.disabled-button {
  background-color: rgb(241, 240, 240);
  pointer-events: none;
}
