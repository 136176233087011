.checkins-header {
  padding-top: 1.15rem;
  @media only screen and (max-width: 1363.98px) {
    padding-top: 1.2rem;
  }
}
.filter-header {
  margin: 0;
  align-items: center;
}
.checkin-column {
  align-self: baseline;
}
.date-input-container.right {
  margin-inline: 3rem 1rem;
}
.client-edit-button.only-child {
  text-align: end;
}

.check-in {
  th {
    &:first-child {
      width: 10%;
    }
    &:nth-child(2) {
      width: 7%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4) {
      width: 1%;
    }
    &:last-child {
      width: 1%;
    }
  }
  &.admin {
    th {
      &:first-child {
        width: 10%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:last-child {
        width: 1%;
      }
    }
  }
}
.table-container-heading-select {
  .office-time-picker {
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
    transition: box-shadow 0.15s ease;
    width: 8rem !important;
    text-align: center;
    padding: 0 !important;
    display: flex;
    align-items: center;
    .rc-time-picker-input {
      text-align: center;
      border: none;
      border-right: 0.5px solid #d3d3d3;
      border-radius: 0;
      cursor: pointer;
    }
    i {
      padding-inline: 0.75rem;
      font-size: 0.9rem;
    }
  }
}
.checkin-time-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-left: 0.2rem;
  }
}

.checkin-popover {
  display: flex;
  justify-content: space-between;
  font-family: 'Fira Sans';
  padding-block: 1rem;

  .popover-head {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #53514d;
    padding-inline: 0.95rem;
  }
  .popover-head {
    text-align: start;
  }
  .check_in-data {
    display: flex;
    justify-content: space-between;
    .timeslot {
      margin-right: 0.5rem;
    }
  }
  .checkin-popover-late {
    .popover-body {
      display: flex;
      span {
        font-size: 14px;
        margin-right: 0.5rem;
      }
      .active-late-switch {
        > div {
          > div {
            > div {
              left: 22px !important;
            }
          }
        }
      }
      .inactive-late-switch {
        > div {
          > div {
            > div {
              left: 3px !important;
            }
          }
        }
      }
    }
  }
}
.popover {
  max-width: 30rem;
}
.rc-time-picker-panel-select-option-selected {
  background: #fff !important;
  color: var(--color-secondary-user);
}
.rc-time-picker-panel-select li:hover {
  background: var(--color-secondary-user) !important;
  color: #fff !important;
}

.time-save-btn {
  position: absolute;
  bottom: 0;
  left: 36%;
}
.header-label {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #6f6b64;
}
.late-coming-count {
  background: rgba(255, 118, 145, 0.1);
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #d1153b;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-block: 0.25rem;
  img {
    margin-right: 0.3rem;
  }
}
