.update-status-form {
  display: grid;
  grid-template-columns: 26% 26% 26% 22%;
  font-family: 'Fira Sans' !important;
  padding: 1.5rem;
  .custom-textarea {
    font-size: 13px;
    width: 82.5%;
    &-label {
      font-weight: 500;
      line-height: 16px;
      color: #363633;
      margin-bottom: 0.5rem;
    }
    &-box {
      color: var(--clr-dark-gray);
      width: 100%;
      border: 1px solid #d3d3d3;
      box-sizing: border-box;
      border-radius: 2px;
      resize: none;
      height: 65px;
      padding: 0.5rem;
      &:focus {
        border: 1px solid #ffc952;
      }
      position: relative;
    }

    &-description {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #8d8c88;
    }
    &-error {
      font-size: 0.7rem;
      margin: -0.5rem 0 0.75rem;
      color: #ef5753;
    }
  }

  .status-moods {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8d8c88;
    img {
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      padding: 0.25rem;
      margin-top: 0.25rem;
      cursor: pointer;
    }
    .selected-mood {
      background: #fff7e4;
      border: 1px solid #ffc952;
    }
  }
  .met-yesterday-goals {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #53514d;
    margin-top: -0.8rem;
    padding-left: 1.5rem !important;
    .unchecked-box,
    .checkmark {
      margin-top: 0 !important;
    }
    input:checked ~ .checkmark {
      background-color: #42be65;
      border-color: #42be65;
    }
  }
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  .complete-checkin-btn {
    width: 11rem !important;
    font-size: 0.75rem;
  }
  .disabled-checkin-btn {
    pointer-events: none;
    background-color: #f3f3f3 !important;
  }
}
.projects-dropdown {
  position: absolute;
  top: 13rem;
  .btn-secondary:first-child {
    visibility: hidden;
  }
  .projects-dropdown-menu {
    max-height: 10rem;
    overflow-y: scroll;
  }

  &-item {
    font-size: 0.75rem;
    color: #8d8c88;
  }
}
.cancel-checkin-btn {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e7e8ea;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Fira Sans';
  font-weight: 300;
  font-size: 12px;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: stretch;
  img {
    margin-right: 0.25rem;
  }
}
