.send-invite-dropdown {
  background-color: unset !important;
  border: 1px solid #d3d3d3 !important;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: none !important;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #8d8c88 !important;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  &:hover,
  &:active,
  &:focus {
    @extend .send-invite-dropdown;
  }
  .button-txt {
    margin-right: 0.5rem;
    padding: 0.25rem;
    border-right: 0.5px solid #d3d3d3;
  }
  &-header {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #53514d;
    text-transform: unset;
    padding-block: 0;
  }
  &-items {
    padding: 0.5rem 1rem;
    max-height: 10rem;
    overflow-y: auto;
  }
  &-footer {
    padding: 0.35rem;
  }
  .dropdown-divider {
    margin: 0 !important;
  }
}
.send-invite-dropdown-role {
  padding-block: 0.25rem;
  .styled-checkbox {
    padding-left: 1.3rem !important;
    font-weight: 400;
  }
  .styled-checkbox input:checked ~ .checkmark {
    background-color: var(--color-secondary-user);
    border-color: var(--color-secondary-user);
  }
}
.new-role-input {
  padding: 0.25rem;
  height: 32px;
}
.send-ivite-btn {
  width: 100% !important;
  padding-block: 0.35rem !important;
  margin-top: 0.35rem !important;
  font-size: 12px;
  line-height: 14px;
  &:hover,
  &:active {
    background-color: var(--color-secondary-user) !important;
    color: #fff !important;
  }
}
.invalid-role-error {
  font-weight: 400;
}
.no-roles-found {
  text-align: center;
  color: #c0c0c0;
}
.sending-invite-loader {
  color: var(--color-secondary-user);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  margin-block: 0.5rem;
}
