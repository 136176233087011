.profile-detail {
  display: flex;
  flex-direction: row;

  &-left,
  &-right {
    width: 100%;
    padding: 1rem;
    margin-right: 2rem;
  }
}
.emergency-contact {
  display: flex;
  padding: 1rem 0rem;
  &-item:last-child {
    margin-right: 0;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-primary-user);
    border-radius: 0.375rem;
    padding: 0.625rem 0.5rem;

    font-size: 0.875rem;
    i {
      color: var(--color-primary-user);
    }
    i:hover {
      color: var(--color-secondary-user);
    }
  }
  &-header {
    display: flex;
    align-items: center;
    color: var(--color-user-dark);
    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }
  }
}
.form-modal {
  .modal-body {
    padding-bottom: 0.25rem;
  }
}

.user-profile-btn {
  padding: 0.25rem 0.5rem;
}

.input-with-label {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  span {
    font-weight: 400;
  }
  textarea {
    color: var(--color-user-dark);
    resize: none;
    height: 100px;
  }
  input,
  select {
    border: 1px solid #cad1d7 !important;
  }
}
.label-for-input {
  display: flex;
  justify-content: space-between;
  span {
    color: var(--color-warning);
    margin-right: 1px;
  }
  i {
    cursor: pointer;
    font-size: 0.9rem;
    color: var(--color-primary-user);
  }
  span:hover,
  i:hover {
    color: var(--color-secondary-user);
  }
  i {
    margin-left: 1rem;
  }
  .disabled {
    pointer-events: none !important;
    color: #aaa;
    background-color: #fff !important;
  }
}
.invalid-form {
  input {
    border-color: var(--color-warning) !important;
  }
  textarea {
    border-color: var(--color-warning) !important;
  }
  select {
    border-color: var(--color-warning) !important;
  }
}

.profile-seperator {
  margin-inline: -1.5rem;
}
