.Toastify {
  .Toastify__toast {
    background: #ffffff;
    box-shadow: 0px 2.04209px 5.44558px rgba(141, 140, 136, 0.1);
    border-radius: 6px;
    color: #53514d;

    display: flex;
    align-items: center;
    &::before {
      content: url('../../../assets/svg/alert-triangle.svg');
      padding-right: 5px;
      max-height: 42px;
    }
  }

  .Toastify__toast-body {
    font-size: 0.875rem;
    color: #53514d;
    &::before {
      content: 'Notification';
      display: block;
      font-weight: 600;
      font-size: 0.95rem;
      margin-bottom: 0.25rem;
    }
  }
  .Toastify__toast--error {
    &::before {
      content: url('../../../assets/svg/cancel-icon.svg');
    }
    .Toastify__toast-body::before {
      content: 'Failure';
    }
  }
  .Toastify__toast--success {
    &::before {
      content: url('../../../assets/svg/ok-icon.svg');
    }
    .Toastify__toast-body::before {
      content: 'Success';
    }
  }
  .Toastify__close-button {
    color: #53514d;
    display: block;
  }
}
