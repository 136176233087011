.erpDetail {
  padding: 1.25rem 1.25rem 2.5rem;

  &__title {
    font-size: 1rem;
    color: var(--clr-light-gray);
  }

  &__cards {
    display: grid;
    grid-template: 1fr 1fr/1fr 1fr;
    gap: 1.25rem;

    &--card {
      padding: 12px 10px 9px;
      border: 1.125px solid #eeedec;
      border-radius: 11.25px;
      height: 120px;

      .card__header {
        font-size: 0.875rem;
        color: var(--clr-light-gray);
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
      }

      .card__stats {
        font-size: 3.125rem;
        font-weight: 700;
        color: #ffc952;
      }
    }

    &--addLink {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      background: #ffefcc;
      border-color: #f8d891;

      span {
        color: var(--clr-light-gray);
        font-size: 0.875rem;
      }

      img {
        width: 24.82px;
        height: 24.82px;
      }
    }
  }
}
