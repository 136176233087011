.modal-unfilled {
  cursor: pointer;
  color: $base-color;
  border: 1px solid $base-color;
  border-radius: 7px;
  font-size: 0.85rem;
  padding: 0.25rem 1.75rem;
  transition: all 0.15s;
  background-color: #fff;
}

.modal-filled {
  @extend .modal-unfilled;
  background-color: $base-color;
  color: #fff;
  display: flex;
}
.modal {
  &-header {
    padding: 1.5rem 1.5rem 0;
    border-bottom: none;
    .modal-title {
      font-size: 1rem;
    }
    .close {
      padding: 1rem 1rem 0 0;
      > span:not(.sr-only) {
        font-size: 1.5rem;
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: inherit;
    }
  }
  &-body {
    padding: 1.5rem;
    input {
      background: #f4f5fa;
      padding: 0.45rem 0.75rem;
      width: 100%;
      border: none;
      border-radius: 5px;
      font-size: 0.9rem;
      &:focus {
        outline: none;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &-footer {
    border-top: none;
    padding: 0 1.5rem 1.5rem;
  }
}
.modal-footer > * {
  margin: 0;
}
