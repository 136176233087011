.user-checkin-status {
  padding: 1.1rem;
  display: grid;
  gap: 0.7rem;
  grid-template-columns: 6% 25% 25% 25% 15%;

  > div:last-child {
    justify-self: center;
    display: flex;
    flex-direction: column;
  }
  .user-image {
    width: 58px;

    > img {
      border-radius: 50%;
      width: inherit;
      border: 2px solid #ffc952;
    }
    position: relative;
    .user-icons {
      display: flex;
      justify-content: space-between;
      img {
        width: 20px;
        position: absolute;
        bottom: 0.1rem;
        &:first-child {
          left: -0.2rem;
        }
      }

      &-mood {
        margin-left: 2.75rem;
      }
    }
  }
  .default-type-color {
    text-transform: capitalize;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 10px;
  }
  .yesterday-type-color {
    @extend .default-type-color;
    color: #64a2ff;
    border: 1px solid #64a2ff;
  }
  .today-type-color {
    @extend .default-type-color;
    color: #42be65;
    border: 1px solid #42be65;
  }
  .blockers-type-color {
    @extend .default-type-color;
    color: #ef5753;
    border: 1px solid #ef5753;
  }
  .status-description {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 13px;
    color: var(--clr-dark-gray);
    margin-top: 0.5rem;
    line-height: 16px;
  }
  .last-update-time {
    font-family: 'Fira Sans';
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    color: #8d8c88;
    margin-top: 0.75rem;
    align-self: end;
  }
}

.update-checkin-status {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  display: flex;
  gap: 0.541rem;
  align-items: center;
  border: 1px solid #e7e8ea;
  border-radius: 4px;
  cursor: pointer;

  span {
    font-size: 0.75rem;
  }
}
