.landing-signup-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.font-color {
  color: #303f40;
  font-weight: 700;
  font-size: 22px !important;
  cursor: pointer;
}

.email-signup {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  border-bottom: 1px solid rgb(219, 219, 219);
  outline: none;
  height: 30px;
  transition: height 1s;
  -webkit-transition: height 1s;
}
.email-signup:focus {
  height: 50px;
  font-size: 16px;
}
.signup-email-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
label {
  color: rgb(219, 219, 219);
}
.p-relative {
  position: relative;
}
.confirm-message {
  position: absolute;
  bottom: 0.25rem;
  width: 100%;
  margin-left: 0.25rem;
  color: var(--color-warning);
  font-size: 0.75rem;
  font-weight: 300;
  i {
    margin-right: 0.25rem;
  }
}

.signup-page {
  display: flex;
  .signup-form {
    background: #ffff;
    margin-top: 6rem;
    flex-basis: 28.5%;
    padding: 3rem;
    font-size: 0.85rem;
    .signin-links {
      display: flex;
      justify-content: space-between;
      .link {
        text-decoration: none;
        color: $green-dark;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: $green-dark;
        }
      }
    }
  }
  .background-image {
    flex-basis: 72.5%;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(-10.5deg, #aaaaaa6e 42%, #ffc9525c 42%);
    }
  }
}

.custom-input {
  padding: 0.65rem 1rem;
  padding-left: 2.8rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 0.85rem;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.025);
  }
  &::placeholder {
    font-weight: 300;
  }
}

.custom-input-group {
  position: relative;
  margin-bottom: 1.2rem;

  .icon {
    position: absolute;
    top: 50%;
    margin-left: 0.75rem !important;
    transform: translateY(-52%);
  }
}

.custom-fw-btn {
  width: 100%;
  padding: 0.5rem;
  background-color: $green-dark;
  border-radius: 5px;
  color: #fff !important;
  border: none;
  &:hover {
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.025);
  }
  &:active {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.025);
  }
}
