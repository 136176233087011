.paginater {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.paginater a {
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #fdc13f;
  color: #fdc13f;
}

.paginater {
  font-weight: normal;
}

.paginater__link--active a {
  color: #fff;
  background: #fdc13f;
}

.paginater__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
.paginaterActive {
  background-color: #fdc13f;
  color: #fff;
}
