.card > hr {
  margin: unset;
}
.activity-list {
  background-color: #fff;

  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.activity-date-container {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
.date-admin-header,
.date-user-header {
  padding: 0.918rem 1.5rem;
}

.activity-spinner-padding {
  padding-top: 1.175rem;
  padding-bottom: 1.5rem;
}

.custom-nav-user-link {
  color: var(--color-primary-user);
  background-color: rgba(211, 211, 211, 0.267);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

  &:hover {
    color: var(--color-secondary-user);
    box-shadow: 0 1px 3px rgba(253, 193, 63, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }
  &.custom-nav-active,
  &.custom-nav-active:hover {
    box-shadow: 0 1px 3px rgba(253, 193, 63, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border-color: rgba(253, 193, 63, 0.15);
  }
}
.custom-nav-active {
  color: var(--color-secondary-user);
  background-color: #ffd67e4b;
}
.custom-nav-active:hover {
  color: var(--color-primary-user);
}
