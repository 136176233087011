@media (min-width: 1365px) {
  .col-custom-cards {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.admin-header {
  font-weight: 500;
  padding-block: 15px 10px;
}

// Admin activity
.tasks {
  &__title {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--clr-light-gray);
  }

  &__btn {
    padding: 0.719rem 0.719rem;
    background: #ffc952;
    border: 0;
    border-radius: 5.35506px;
    font-family: inherit;
    font-size: 1rem;
    color: var(--clr-light-gray);

    img {
      margin-right: 0.413rem;
    }
  }

  &__footer {
    margin-top: 2.753rem;

    &--icons {
      gap: 0.438rem;
    }

    &--icon {
      cursor: pointer;
    }
  }
}
.timeline-wrapper::before {
  content: '';
  top: 21%;
  left: 0px;
  width: 150px;
  height: 160px;
  position: absolute;
  background: linear-gradient(
    90deg,
    #ffffff 11.31%,
    rgba(255, 255, 255, 0.8) 32.29%,
    rgba(255, 255, 255, 0) 59.85%
  );
  z-index: 999;
}
.timeline-wrapper::after {
  content: '';
  background: linear-gradient(
    270deg,
    #ffffff 11.31%,
    rgba(255, 255, 255, 0.8) 32.29%,
    rgba(255, 255, 255, 0) 59.85%
  );
  top: 21%;
  right: 0px;
  width: 150px;
  height: 160px;
  position: absolute;
  z-index: 999;
}
