.leave-date-picker {
  .invalid-date-message {
    color: var(--color-warning);
    font-size: 0.75rem;
    span {
      margin-left: 0.25rem;
    }
  }
  display: flex;
  justify-content: center;
  display: grid;
  place-items: center;
  color: var(--color-primary-user);
  .DayPicker-Caption {
    text-align: center;
  }

  .DayPicker-Day {
    text-align: left;
    border-radius: 0;
    font-weight: 300;
    padding: 0.25rem 1.45rem;
    font-size: 0.85rem;
    position: relative;
    border: 1px solid #f6f6f6;
    border-style: none solid solid none;
    &--selected:not(.DayPicker-Day--outside) {
      background-color: #ffc952 !important;
      color: #fff;
      &:hover {
        background-color: #ffc952;
      }
    }
    &--today:not(.DayPicker-Day--outside) {
      color: #aaaaaa69;
      font-weight: bold;
    }
    &--applied:not(.DayPicker-Day--outside) {
      pointer-events: none;
      background-color: rgb(221, 221, 221);
      color: var(--color-primary-user);
      opacity: 0.8;
    }
    &--disabled:not(.DayPicker-Day--outside) {
      pointer-events: none;
    }
  }
  .weekday-content {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    padding-inline: 0.5rem;
    width: 100%;
    img {
      margin-left: 0.25rem;
    }
  }
  .DayPicker {
    &-Month {
      border-collapse: separate;
    }
    &-Day {
      border: 1px solid #f6f6f6;
      border-style: none solid solid none;
    }
    &-Week {
      &:first-child {
        .DayPicker-Day {
          border-top-style: solid;
        }
        .DayPicker-Day:first-child {
          border-top-left-radius: 7px;
        }
        .DayPicker-Day:last-child {
          border-top-right-radius: 7px;
        }
      }
      &:last-child {
        .DayPicker-Day:first-child {
          border-bottom-left-radius: 7px;
        }
        .DayPicker-Day:last-child {
          border-bottom-right-radius: 7px;
        }
      }
      .DayPicker-Day:first-child {
        border-left-style: solid;
      }
    }
  }
}

.custom-alert-msg {
  font-size: 0.85rem;
  color: #b5b5b5;
  i {
    margin-right: 0.25rem;
  }
}
